export async function useGetBlobUrl(url: string) {
  try {
    const data = await fetch('/api/platform/media-url', {
      method: 'POST',
      body: JSON.stringify({ url }),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const blob = await data.blob()
    return URL.createObjectURL(blob)
  }
  catch (error) {
    return ''
  }
}
